import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import { useSelector, useDispatch } from "react-redux";
import { expiryFilters } from "../database/expiryFilters";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {
  fetchCompanyDetails,
  fetchCompanyList,
  fetchUserList,
  fetchUserData,
  updateCompanyReportFields,
  fetchCompanyReportFields,
} from "../database/firebaseApis";
import { setAuthenticated } from "../redux/authSlice";
import { Dropdown, Form } from "react-bootstrap";
import filteringLogic from "../utils/filteringLogic";
const { doc, updateDoc, getDoc } = require("firebase/firestore");
const firestore = require("../database/firebase");

export default function Dashboard() {
  const [companyFilters, setCompanyFilters] = React.useState("noFilterApplied");
  const [expirySpanState, setExpirySpanState] = React.useState("0");
  const filterNames = {
    0: "Already Expired",
    30: "30 Days Remaining",
    60: "60 Days Remaining",
    45: "45 Days Remaining",
  };
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [switches, setSwitches] = React.useState({
    daily: false,
    weekly: false,
    monthly: false,
    faceBiometric: false,
  });
  const [companyDetailsState, setCompanyDetailsState] = React.useState(null);
  const [companyList, setCompanyList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedUSer, setSelectedUser] = React.useState("");
  const [resetTimerOn, setResetTimerOn] = React.useState(false);
  const [verifyTimerOn, setVerifyTimerOn] = React.useState(false);
  const [secretTimerOn, setSecretTimerOn] = React.useState(false);
  const [filteredCompanyList, setFilteredCompanyList] = React.useState([]);
  const reportFieldState = useSelector(
    (state) => state.state.companyReportFields
  );

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);
  React.useEffect(() => {
    console.log("Hii sullo paymentId", companyDetailsState);
  }, [companyDetailsState]);
  React.useEffect(() => {
    if (selectedCompany) {
      // console.log("I am the data", selectedCompany, switches);
      updateCompanyReportFields(selectedCompany, switches);
    }
  }, [switches]);
  React.useEffect(() => {
    // Fetch company list on component mount

    fetchCompanies();
  }, []);

  // Update filtered list when companyList or companyFilters changes
  React.useEffect(() => {
    const filteredCompany = companyList.filter((company) =>
      filteringLogic(company, companyFilters, expirySpanState)
    );
    setFilteredCompanyList(filteredCompany);
  }, [companyFilters, expirySpanState, companyList]);

  React.useEffect(() => {
    // console.log("reportFieldStatereportFieldState", reportFieldState);
  }, [reportFieldState]);

  React.useEffect(() => {
    fetchCompanyDetailsFunc(selectedCompany);

    fetchUsers(selectedCompany);
    fetchCompanyReportFieldsComp(selectedCompany);
  }, [selectedCompany]);

  React.useEffect(() => {
    fetchUserDetails(selectedCompany, selectedUSer);
  }, [selectedUSer]);

  // React.useEffect(() => {
  //   console.log("Hii I am companyDetailsState", companyDetailsState);
  // }, [companyDetailsState]);

  React.useEffect(() => {
    console.log("Hii I am userDetails", userDetails);
  }, [userDetails]);

  const fetchCompanies = async () => {
    try {
      const companies = await fetchCompanyList();
      setCompanyList(companies);
    } catch (error) {
      console.error("Error fetching company list:", error);
    }
  };

  const fetchCompanyDetailsFunc = async (companyId) => {
    try {
      const companyDetailsData = await fetchCompanyDetails(companyId);

      setCompanyDetailsState(companyDetailsData);
    } catch (error) {
      console.error("Error fetching company details", error);
    }
  };
  // Function to handle toggling the state of a switch
  const handleToggleSwitch = (switchName) => {
    setSwitches((prevSwitches) => ({
      ...prevSwitches,
      [switchName]: !prevSwitches[switchName],
    }));
  };

  const fetchCompanyReportFieldsComp = async (companyId) => {
    try {
      const reportData = await fetchCompanyReportFields(companyId);
      // console.log("I am report Data", reportData);
      const { daily, weekly, monthly, faceBiometric } = reportData;
      setSwitches({ daily, weekly, monthly, faceBiometric });
    } catch (err) {
      console.log("Error Occured", err);
    }
  };
  const fetchUsers = async (companyId) => {
    try {
      const users = await fetchUserList(companyId);
      setUserList(users);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  const fetchUserDetails = async (companyId, userId) => {
    try {
      const userDetails = await fetchUserData(companyId, userId);
      setUserDetails(userDetails);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const handleReset = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }

      const userDocRef = doc(
        firestore,
        "Company",
        userDetails.companyId,
        "users",
        userDetails.id
      );
      // console.log("User Document Reference:", userDocRef);

      await updateDoc(userDocRef, {
        deviceId: "",
      });

      console.log("Device data cleared successfully in Firebase.");

      // Update the local state to reflect the changes
      setUserDetails((prevUserData) => ({
        ...prevUserData,
        deviceId: "",
      }));

      // Set timerOn to true
      setResetTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setResetTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds
      // console.log("device data is cleared on ui");
    } catch (error) {
      console.error("Error updating device data in Firebase:", error);
    }
  };

  const handleVerify = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }
      console.log("we are user details", userDetails);
      // Send a POST request to the verification email API endpoint
      const response = await fetch(
        "https://us-central1-stemm-trak.cloudfunctions.net/app/api/sendverifiedEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userDetails.Email, // Assuming userDetails.Email contains the user's email
            name: userDetails.Name, // Assuming userDetails.Name contains the user's name
          }),
        }
      );

      // Set timerOn to true
      setVerifyTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setVerifyTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds
      // Handle the response if needed
      console.log("Verification email sent successfully:", response);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const secretKeyHandler = async () => {
    try {
      if (!userDetails) {
        console.error("User data is not available.");
        return;
      }

      setSecretTimerOn(true);

      // Set a timer to set timerOn back to false after 2 minutes
      setTimeout(() => {
        setSecretTimerOn(false);
      }, 2 * 60 * 1000); // 2 minutes in milliseconds

      // Handle the response if needed
      // console.log("secret keyyyy successfully:", response);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  const handleLogout = async () => {
    setSelectedCompany("");
    setSelectedUser("");
    setUserDetails(null);
    dispatch(setAuthenticated(false));
  };

  return (
    <Container className="bg-light border border rounded-lg shadow-lg">
      <Row className="py-3 bg-secondary bg-light align-items-center">
        <Col xs={2}>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="expiry-dropdown">
              Expiry Filters
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-1">
              {["0", "30", "45", "60"].map((filter) => (
                <Row className="align-items-center mb-2" key={filter}>
                  <Col xs="auto" className="pr-2">
                    <Form.Check
                      onChange={() => setExpirySpanState(filter)} // Use the new handler
                      checked={expirySpanState === filter}
                      type="checkbox"
                      id={`expiry-${filter}`}
                    />
                  </Col>
                  <Col>
                    <span className="filter-name">{filterNames[filter]}</span>
                  </Col>
                </Row>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col xs={2}>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="plan-type-dropdown">
              Plan Type Filters
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-1">
              {expiryFilters.map((filter) => (
                <Row className="align-items-center mb-2" key={filter.key}>
                  <Col xs="auto" className="pr-2">
                    <Form.Check
                      onChange={() => setCompanyFilters(filter.key)}
                      checked={filter.key === companyFilters}
                      type="checkbox"
                      id={`plan-${filter.key}`}
                    />
                  </Col>
                  <Col>
                    <span className="filter-name">{filter.name}</span>
                  </Col>
                </Row>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Col xs={2}>
          <h3>AdminPanel STEMM One</h3>
        </Col>

        <Col xs={2}>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="plan-type-dropdown">
              Payments Info
            </Dropdown.Toggle>

            <Dropdown.Menu className="p-1">
              <Dropdown.Item>
                {companyDetailsState?.featuresPayment &&
                  companyDetailsState?.featuresPayment[0].paymentId}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col xs={4} className="text-end">
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Col>
      </Row>
      <Row className="py-3">
        <Col xs lg="3">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Row>
                      <Col>
                        {" "}
                        <b>Company Name</b>
                      </Col>
                    </Row>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredCompanyList.length > 0 &&
                  filteredCompanyList.map((company) => (
                    <TableRow
                      key={company.id}
                      // sx={{ "&:last-child td, &:last-child th": { border: 2 } }}
                      sx={{
                        border: selectedCompany === company.id ? 2 : 0,
                        background:
                          selectedCompany === company.id ? "#E5E4E2" : "",
                      }}
                    >
                      <TableCell
                        onClick={() => {
                          setSelectedCompany(company.id);
                          setSelectedUser("");
                          setUserDetails("");
                        }}
                        component="th"
                        scope="row"
                        sx={{ cursor: "pointer" }}
                      >
                        {company.id}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col xs lg="3">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Users</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  height: "500px",
                  overflowY: "auto",

                  display: "block", // Ensures the TableBody behaves like a block-level element
                }}
              >
                {userList.map((user) => (
                  <TableRow
                    key={user?.id}
                    sx={{
                      border: selectedUSer === user?.id ? 2 : 0,
                      background: selectedUSer === user.id ? "#E5E4E2" : "",
                    }}
                  >
                    <TableCell
                      onClick={() => setSelectedUser(user?.id)}
                      component="th"
                      scope="row"
                      sx={{ cursor: "pointer" }}
                    >
                      {user.id}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Col>
        <Col xs lg="6">
          <h6>Attendance Reports</h6>
          <Row className="pb-4">
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <div>Daily</div>
                <Switch
                  checked={switches.daily}
                  onChange={() => handleToggleSwitch("daily")}
                />
              </div>{" "}
            </Col>{" "}
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <div>Weekly</div>
                <Switch
                  checked={switches.weekly}
                  onChange={() => handleToggleSwitch("weekly")}
                />
              </div>{" "}
            </Col>{" "}
            <Col>
              <div className="d-flex justify-content-center align-items-center">
                <div>Monthly</div>
                <Switch
                  checked={switches.monthly}
                  onChange={() => handleToggleSwitch("monthly")}
                />
              </div>{" "}
            </Col>
            <Col>
              <div className="d-flex align-items-center">
                <div>Face BioMetrics</div>
                <Switch
                  checked={switches.faceBiometric}
                  onChange={() => handleToggleSwitch("faceBiometric")}
                />
              </div>{" "}
            </Col>
          </Row>
          {companyDetailsState && !selectedUSer && (
            <Row>
              <Col>
                <div style={{ height: "300px", overflowY: "scroll" }}>
                  <p>{`GST Number: ${companyDetailsState.GSTNumber}`}</p>
                  <p>{`TTL Expiry Date: ${
                    companyDetailsState?.TTL_ExpiryDate
                      ? companyDetailsState.TTL_ExpiryDate.toDate().toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Acquired Calendar Task: ${companyDetailsState.acquiredCalenderTask}`}</p>
                  <p>{`Acquired Customer Creation: ${companyDetailsState.acquiredCustomerCreation}`}</p>
                  <p>{`Acquired Face Attendance: ${companyDetailsState.acquiredFaceAttendance}`}</p>
                  <p>{`Acquired Field Tracking: ${companyDetailsState.acquiredFieldTracking}`}</p>
                  <p>{`Acquired Leave Policy: ${companyDetailsState.acquiredLeavePolicy}`}</p>
                  <p>{`Acquired Payroll: ${companyDetailsState.acquiredPayroll}`}</p>
                  <p>{`Acquired Reimbursement: ${companyDetailsState.acquiredReimbursement}`}</p>
                  <p>{`Acquired Route Optimization: ${companyDetailsState.acquiredRouteOptimization}`}</p>
                  <p>{`Acquired Task OTP Completion: ${companyDetailsState.acquiredTaskOtpCompletion}`}</p>
                  <p>{`Acquired Time Sheet Filling: ${companyDetailsState.acquiredTimeSheetFilling}`}</p>
                  <p>{`Admin Contact Number: ${companyDetailsState.adminContactNumber}`}</p>
                  <p>{`Admin Email: ${companyDetailsState.adminEmail}`}</p>
                  <p>{`Allow Company Task: ${companyDetailsState.allowCompanyTask}`}</p>
                  <p>{`Allow Work From Home: ${companyDetailsState.allowWorkFromHome}`}</p>
                  <p>{`Apply Regularization For Month: ${companyDetailsState.applyRegularizationForMonth}`}</p>
                  <p>{`Company Email: ${companyDetailsState.companyEmail}`}</p>
                  <p>{`Company Name: ${companyDetailsState.companyName}`}</p>
                  <p>{`Created Date: ${
                    companyDetailsState?.createdDate
                      ? companyDetailsState.createdDate
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Customer Form Link: ${
                    companyDetailsState.customerFormLink || "N/A"
                  }`}</p>
                  <p>{`Daily Report PDF: ${
                    companyDetailsState.dailyReportPdf ? "Yes" : "No"
                  }`}</p>
                  <p>{`Enable PDF Creation: ${
                    companyDetailsState.enablePdfCreation ? "Yes" : "No"
                  }`}</p>
                  <p>{`Expire Calendar Task Plan: ${
                    companyDetailsState?.expireCalenderTaskPlan
                      ? companyDetailsState.expireCalenderTaskPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Customer Creation Plan: ${
                    companyDetailsState?.expireCustomerCreationPlan
                      ? companyDetailsState.expireCustomerCreationPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Face Attendance Plan: ${
                    companyDetailsState?.expireFaceAttendancePlan
                      ? companyDetailsState.expireFaceAttendancePlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Field Tracking Plan: ${
                    companyDetailsState?.expireFieldTrackingPlan
                      ? companyDetailsState.expireFieldTrackingPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Leave Policy Plan: ${
                    companyDetailsState?.expireLeavePolicyPlan
                      ? companyDetailsState.expireLeavePolicyPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Payroll Plan: ${
                    companyDetailsState?.expirePayrollPlan
                      ? companyDetailsState.expirePayrollPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Reimbursement Plan: ${
                    companyDetailsState?.expireReimbursementPlan
                      ? companyDetailsState.expireReimbursementPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Route Optimization Plan: ${
                    companyDetailsState?.expireRouteOptimizationPlan
                      ? companyDetailsState.expireRouteOptimizationPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Task OTP Completion Plan: ${
                    companyDetailsState?.expireTaskOtpCompletionPlan
                      ? companyDetailsState.expireTaskOtpCompletionPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Expire Time Sheet Filling Plan: ${
                    companyDetailsState?.expireTimeSheetFillingPlan
                      ? companyDetailsState.expireTimeSheetFillingPlan
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Face Biometric: ${
                    companyDetailsState.faceBiometric ? "Yes" : "No"
                  }`}</p>
                  <p>{`Modules: ${JSON.stringify(
                    companyDetailsState.modules
                  )}`}</p>
                  <p>{`Monthly Report PDF: ${
                    companyDetailsState.monthlyReportPdf ? "Yes" : "No"
                  }`}</p>
                  <p>{`Number of Employees: ${companyDetailsState.numberOfEmployees}`}</p>
                  <p>{`Pending Notification Time: ${companyDetailsState.pendingNotificationTime}`}</p>
                  <p>{`Pending Notification Timezone: ${companyDetailsState.pendingNotificationTimezone}`}</p>
                  <p>{`Plan Name: ${companyDetailsState.planName}`}</p>
                  <p>{`Total Calendar Task: ${companyDetailsState.totalCalenderTask}`}</p>
                  <p>{`Total Customer Creation: ${companyDetailsState.totalCustomerCreation}`}</p>
                  <p>{`Total Face Attendance: ${companyDetailsState.totalFaceAttendance}`}</p>
                  <p>{`Total Field Tracking: ${companyDetailsState.totalFieldTracking}`}</p>
                  <p>{`Total Leave Policy: ${companyDetailsState.totalLeavePolicy}`}</p>
                  <p>{`Total Payroll: ${companyDetailsState.totalPayroll}`}</p>
                  <p>{`Total Reimbursement: ${companyDetailsState.totalReimbursement}`}</p>
                  <p>{`Total Route Optimization: ${companyDetailsState.totalRouteOptimization}`}</p>
                  <p>{`Total Task OTP Completion: ${companyDetailsState.totalTaskOtpCompletion}`}</p>
                  <p>{`Total Time Sheet Filling: ${companyDetailsState.totalTimeSheetFilling}`}</p>
                  <p>{`Used Task Count: ${companyDetailsState.usedTaskCount}`}</p>
                  <p>{`Validity Upto: ${
                    companyDetailsState?.validityUpto
                      ? companyDetailsState.validityUpto
                          .toDate()
                          .toLocaleDateString()
                      : "N/A"
                  }`}</p>
                  <p>{`Weekly Report PDF: ${
                    companyDetailsState.weeklyReportPdf ? "Yes" : "No"
                  }`}</p>
                  <p>{`Work From Home Per Month: ${companyDetailsState.workFromHomePerMonth}`}</p>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            {" "}
            {userDetails && (
              <div style={{ height: "300px", overflowY: "scroll" }}>
                <p>{`Battery Level: ${userDetails?.Batterylevel}`}</p>
                <p>{`Branch: ${userDetails?.Branch}`}</p>
                <p>{`Casual Leaves: ${userDetails?.CasualLeaves}`}</p>
                <p>{`Company Status: ${userDetails?.CompanyStatus}`}</p>
                <p>{`Department: ${userDetails?.Department}`}</p>
                <p>{`Designation: ${userDetails?.Designation}`}</p>
                <p>{`Device: ${userDetails?.Device}`}</p>
                <p>{`Email: ${userDetails?.Email}`}</p>
                <p>{`Facebook URL: ${userDetails?.FacebookUrl || "N/A"}`}</p>
                <p>{`GeoFence Attendance: ${userDetails?.GeoFenceAttendance}`}</p>
                <p>{`Grace Time: ${userDetails?.GraceTime}`}</p>
                <p>{`ID: ${userDetails?.ID}`}</p>
                <p>{`Joining Date: ${new Date(
                  userDetails?.JoiningDate
                ).toLocaleDateString()}`}</p>
                <p>{`Last Location: ${userDetails?.LastLocation?.join(
                  ", "
                )}`}</p>
                <p>{`LinkedIn URL: ${userDetails?.LinkedinUrl || "N/A"}`}</p>
                <p>{`Name: ${userDetails?.Name}`}</p>
                <p>{`Password: ${userDetails?.Password}`}</p>
                <p>{`Phone: ${userDetails?.Phone}`}</p>
                <p>{`Reporting Manager: ${userDetails?.ReportingManager}`}</p>
                <p>{`Reporting Manager ID: ${userDetails?.ReportingManagerId}`}</p>
                <p>{`Role ID: ${userDetails?.RoleID}`}</p>
                <p>{`Shift Details: ${userDetails?.ShiftDetails}`}</p>
                <p>{`Sick Leaves: ${userDetails?.SickLeaves}`}</p>
                <p>{`Status: ${userDetails?.Status}`}</p>
                <p>{`User Type: ${userDetails?.UserType}`}</p>
                <p>{`WFH: ${userDetails?.WFH}`}</p>
                <p>{`WFH Leaves: ${userDetails?.WFHLeaves}`}</p>
                <p>{`Active: ${userDetails?.active}`}</p>
                <p>{`Aadhar Card: ${userDetails?.addharCard}`}</p>
                <p>{`Admin: ${userDetails?.admin}`}</p>
                <p>{`Allow Calender Task: ${userDetails?.allowCalenderTask}`}</p>
                <p>{`Allow Customer Creation: ${userDetails?.allowCustomerCreation}`}</p>
                <p>{`Allow Face Attendance: ${userDetails?.allowFaceAttendance}`}</p>
                <p>{`Allow Field Tracking: ${userDetails?.allowFieldTracking}`}</p>
                <p>{`Allow Leave Creation: ${userDetails?.allowLeaveCreation}`}</p>
                <p>{`Allow Payroll: ${userDetails?.allowPayroll}`}</p>
                <p>{`Allow Reimbursement: ${userDetails?.allowReimbursement}`}</p>
                <p>{`Allow Route Optimization: ${userDetails?.allowRouteOptimization}`}</p>
                <p>{`Allow Task OTP Completion: ${userDetails?.allowTaskOtpCompletion}`}</p>
                <p>{`Allow Timesheet Filling: ${userDetails?.allowTimeSheetFilling}`}</p>
                <p>{`Branches: ${userDetails?.branches?.join(", ")}`}</p>
                <p>{`Company ID: ${userDetails?.companyId}`}</p>
                <p>{`Country Code: ${userDetails?.countryCode}`}</p>
                <p>{`Cross Reporting ID: ${userDetails?.crossReportingId}`}</p>
                <p>{`Custom Fields Model: ${JSON.stringify(
                  userDetails?.customFieldsModel
                )}`}</p>
                <p>{`Device ID: ${userDetails?.deviceId}`}</p>
                <p>{`Dial Code: ${userDetails?.dialCode}`}</p>
                <p>{`Email Verified: ${userDetails?.emailVerified}`}</p>
                <p>{`Employee ID: ${userDetails?.employeeId}`}</p>
                <p>{`Employment Type: ${userDetails?.employmentType}`}</p>
                <p>{`Face Biometric: ${userDetails?.faceBiometric}`}</p>
                <p>{`Face Data: ${userDetails?.faceData}`}</p>
                <p>{`FCM Token: ${userDetails?.fcmToken}`}</p>
                <p>{`Gender: ${userDetails?.gender}`}</p>
                <p>{`Homepage Tutorial Mobile: ${userDetails?.homepageTutorailMobile}`}</p>
                <p>{`ID: ${userDetails?.id}`}</p>
                <p>
                  {`Image URL: `}
                  <img
                    style={{ height: "100px" }}
                    src={userDetails?.imageUrl}
                    alt="User"
                  />
                </p>
                <p>{`Last Login Time: ${new Date(
                  userDetails?.lastLoginTime * 1000
                ).toLocaleString()}`}</p>
                <p>{`Late Remark Days: ${userDetails?.lateRemarkDays}`}</p>
                <p>{`Leave Structure ID: ${userDetails?.leaveStructureId}`}</p>
                <p>{`Leave Structure Name: ${userDetails?.leaveStructureName}`}</p>
                <p>{`Lunch Duration: ${userDetails?.lunchDuration}`}</p>
                <p>{`Lunch Time: ${new Date(
                  userDetails?.lunchTime
                ).toLocaleTimeString()}`}</p>
                <p>{`Notification Check In: ${userDetails?.notificationCheckIn}`}</p>
                <p>{`Notification Check Out: ${userDetails?.notificationCheckOut}`}</p>
                <p>{`Notification Clock In: ${userDetails?.notificationClockIn}`}</p>
                <p>{`Notification Clock Out: ${userDetails?.notificationClockOut}`}</p>
                <p>{`Notification Lead: ${userDetails?.notificationLead}`}</p>
                <p>{`Notification Leave: ${userDetails?.notificationLeave}`}</p>
                <p>{`Notification Regularization: ${userDetails?.notificationRegularization}`}</p>
                <p>{`Notification Reimbursement: ${userDetails?.notificationReimbursement}`}</p>
                <p>{`PAN Card: ${userDetails?.panCard}`}</p>
                <p>{`Person Security ID: ${userDetails?.personSecurityId}`}</p>
                <p>{`Salary Calculation Type: ${userDetails?.salaryCalculationType}`}</p>
                <p>{`Salary Structure ID: ${userDetails?.salaryStructureId}`}</p>
                <p>{`Salary Structure Name: ${userDetails?.salaryStructureName}`}</p>
                <p>{`Search Index: ${userDetails?.searchIndex?.join(", ")}`}</p>
                <p>{`Secret Key: ${userDetails?.secretKey}`}</p>
                <p>{`Selected: ${userDetails?.selected}`}</p>
                <p>{`Shift Structure ID: ${userDetails?.shiftStructureId}`}</p>
                <p>{`Shift Structure Name: ${userDetails?.shiftStructureName}`}</p>
                <p>{`Shift Times: ${userDetails?.shiftTimes}`}</p>
                <p>{`Time Line Data Filling Type: ${userDetails?.timeLineDataFillingType}`}</p>
                <p>{`Tracking Type: ${userDetails?.trackingType}`}</p>
                <p>{`UPI ID: ${userDetails?.upi_Id}`}</p>
                <p>{`User Hourly Salary: ${userDetails?.userHourlySalary}`}</p>
                <p>{`User Salary: ${userDetails?.userSalary}`}</p>
                <p>{`Version Code: ${userDetails?.versionCode}`}</p>
                <p>{`Web Access: ${userDetails?.webAccess}`}</p>
                <p>{`Weekly Schedule: ${userDetails?.weeklySchedule?.join(
                  ", "
                )}`}</p>
              </div>
            )}
          </Row>

          {selectedUSer && (
            <Row className="mt-4">
              {" "}
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button
                  onClick={handleVerify}
                  variant="contained"
                  disabled={verifyTimerOn}
                >
                  Verify Email
                </Button>
                <Button
                  onClick={secretKeyHandler}
                  variant="contained"
                  disabled={secretTimerOn}
                  href="#contained-buttons"
                >
                  Create / Update Secret Key
                </Button>
                <Button
                  onClick={handleReset}
                  disabled={resetTimerOn}
                  variant="contained"
                  href="#contained-buttons"
                >
                  Reset
                </Button>
              </Stack>
              {secretTimerOn ? (
                <p className="p-1">For Secret Key Wait for two minuts</p>
              ) : (
                ""
              )}
              {resetTimerOn ? (
                <p className="p-1">For Reset Key Wait for two minuts</p>
              ) : (
                ""
              )}
              {verifyTimerOn ? (
                <p className="p-1">For Verify Key Wait for two minuts</p>
              ) : (
                ""
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}
