export const expiryFilters = [
  {
    key: "expireCalenderTaskPlan",
    name: "Calendar Task Plan",
  },
  {
    key: "expireCustomerCreationPlan",
    name: "Customer Creation Plan",
  },
  {
    key: "expireFaceAttendancePlan",
    name: "Face Attendance Plan",
  },
  {
    key: "expireFieldTrackingPlan",
    name: "Field Tracking Plan",
  },
  {
    key: "expireLeavePolicyPlan",
    name: "Leave Policy Plan",
  },
  {
    key: "expirePayrollPlan",
    name: "Payroll Plan",
  },
  {
    key: "expireReimbursementPlan",
    name: "Reimbursement Plan",
  },
  {
    key: "expireRouteOptimizationPlan",
    name: "Route Optimization Plan",
  },
  {
    key: "expireTaskOtpCompletionPlan",
    name: "Task OTP Completion Plan",
  },
  {
    key: "expireTimeSheetFillingPlan",
    name: "Time Sheet Feeling Plan",
  },
  {
    key: "noFilterApplied",
    name: "No Filter Applied",
  },
];
